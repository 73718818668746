import React from 'react';
import Card from 'react-bootstrap/Card';
import { Link } from 'gatsby';
import arrow from '../assets/images/arrow-over.webp';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function ProductCard({
  screenText,
  overlayText,
  overlaymobText,
  backgroundImage,
  backgroundcss,
  linkto,
}: {
  screenText: string;
  overlayText: string;
  overlaymobText: string;
  backgroundImage: string;
  backgroundcss: string;
  linkto: string;
}) {
  return (
    <>
      <div className='card-div mb-3 service-card-space'>
        <Link to={'/services' + linkto}>
          <div className='card-wrapper p-0 '>
            <Card className='card-body p-0 pos-relative'>
              <LazyLoadImage
                effect='blur'
                src={backgroundImage}
                alt='bg-image'
                className='card-img h-auto w-100'
              />
              <div className='show-hover pos-absolute'>
                <Card.Title className='color-white fw-600 fnt-18 fnt-lg-24 ms-3 mb-2'>
                  {screenText}
                </Card.Title>
                <Card.Body className='starter'>
                  <>
                    <Card.Text className='color-white fnt-14  fnt-lg-14 fnt-xl-16 pt-0 lh-22'>
                      {overlayText}
                    </Card.Text>
                    <img className='arrow-over-img' src={arrow} alt='Arrow Icon' />
                  </>
                </Card.Body>
              </div>
            </Card>
          </div>
        </Link>
      </div>
    </>
  );
}

export default ProductCard;
