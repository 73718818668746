import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import OtherServicesCard from './OtherServicesCard';

function ProductServicesCard({ data }: any) {
  return (
    <div className='bg-white mb-2 py-4 py-lg-5'>
      <Container>
        <h3 className='color-lightGreen mb-3 text-center fnt-14 fnt-md-16  fw-700 text-uppercase'>
          Our services
        </h3>
        <div className='fw-700 fnt-lg-32 fnt-20 lw-56 text-center text-black mb-2 pb-2'>
          Discover Our Diverse Range of Cutting-Edge Services
        </div>
        <div className='row row-card pe-0'>
          <Row xs={1} sm={1} md={3} lg={4} xxl={5} className='pe-0'>
            {data.map((othercard: any, index: any) => (
              <Col key={othercard.id} className='pb-4'>
                <OtherServicesCard {...othercard} />
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default ProductServicesCard;
