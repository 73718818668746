import React from 'react';
import Acnavbar from '../components/Acnavbar';
import '../assets/scss/index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import ProductCardDetails from '../components/ProductCardDetails';
import Footer from '../components/footer';
import ProductServicesCard from '../components/ProductServicesCard';
import Contact from '../components/Contact';
import ProductDescription from '../components/ProductDescription';
import ProductCardData from '../json/product-cards.json';
import Seo from '../shared/seo';
import Wrapper from '../components/Wrapper';
import ProductBottom from '../components/ProductBottom';

const ServicesTemplate = ({ pageContext }: any) => {
  const filteredData = ProductCardData.filter((item: any) => item.slug !== pageContext.slug);
  return (
    <Wrapper>
      <Seo
        title={pageContext.serviceHomePage.seo_title}
        description={pageContext.serviceHomePage.seo_description}
      />
      <div className='common-background overflow-hidden bg-cover'>
        <Acnavbar home={false} pathname={'services'} logo={''} logoLogic={false} />
        <ProductDescription
          topheader={pageContext.serviceHomePage.topheader}
          header={pageContext.serviceHomePage.header}
          headerText={pageContext.serviceHomePage.headerText}
          showButton={true}
        />
        <ProductBottom
          bottom_header={pageContext.serviceCardData.serviceCardHeader.header}
          bottom_desc={pageContext.serviceCardData.serviceCardHeader.paragraph}
        />
        <ProductCardDetails data={pageContext?.serviceCardData} />
        <ProductServicesCard data={filteredData} />
        <div className='bg-white'>
          <Contact />
        </div>
        <Footer />
      </div>
    </Wrapper>
  );
};

export default ServicesTemplate;
